import { render, staticRenderFns } from "./NewPost.vue?vue&type=template&id=41e36830&scoped=true&"
import script from "./NewPost.vue?vue&type=script&lang=js&"
export * from "./NewPost.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41e36830",
  null
  
)

export default component.exports
<template>
    <div class="my-10 flex flex-col xl:flex-row justify-between">
<!--        <div class="bg-white rounded-md p-2 mb-6">-->
<!--            <div>-->
<!--                <label for="email" class="sr-only">Email</label>-->
<!--                <div class="relative rounded-md shadow-sm">-->
<!--                    <input id="email" class="form-input border-gray-500 bg-gray-300 hover:bg-white hover:border-indigo-600 block w-full sm:text-sm sm:leading-5 placeholder-gray-500" placeholder="Post Title">-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <div class="w-10/12 mr-6 order-2 xl:order-1">
            <div class="relative rounded-md shadow-sm mb-2 ">
<!--                <label for="newPostTitle">Title</label>-->
                <label for="newPostTitle"></label>
                <input ref="newPostTitle" id="newPostTitle" v-model="title"
                       type="text"
                       @focusin="titleFocusIn"
                       @focusout="titleFocusOut"
                       class="border-gray-500 bg-white rounded-md hover:border-indigo-600 block w-full sm:text-sm sm:leading-5 placeholder-gray-500 focus:bg-white"
                       :class="{'border-red-500 placeholder-red-500': $v.title.$error}"
                       placeholder="title your post">
            </div>

            <jitap-editor ref="jitapEditor" class="shadow-sm"></jitap-editor>

            <div class="flex justify-end  mt-2">
                <div>
                    <button @click="cancelPressed" type="button" class="mr-2 text-lg inline-flex items-center px-6 py-2 border border-white text-sm leading-4 font-medium rounded-md text-white  hover:bg-jiruto-flatRed focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-red-500 transition ease-in-out duration-150">
                        Cancel
                    </button>
                    <button @click="postPressed" type="button" class="text-lg inline-flex items-center px-6 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-red-500 transition ease-in-out duration-150">
                        Post
                    </button>
                </div>
            </div>
        </div>

        <div class="order-1 xl:order-2">
            <div class="border bg-purple-100 border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200s">
                <div class="p-6">
                    <h2 class="text-lg leading-6 font-medium text-gray-900">Before you post</h2>
                    <p class="mt-4 text-sm leading-5 text-gray-500">Make sure you're following our general guidelines</p>
<!--                    <p class="mt-8">-->
<!--                        <span class="text-4xl leading-10 font-extrabold text-gray-900">$24</span>-->
<!--                        <span class="text-base leading-6 font-medium text-gray-500">/mo</span>-->
<!--                    </p>-->
                </div>
                <div class="pt-6 pb-8 px-6">
                    <h3 class="text-xs leading-4 font-medium text-gray-900 tracking-wide uppercase">Pay attention:</h3>
                    <ul class="mt-6 space-y-4">
                        <li class="flex space-x-3">
                            <!-- Heroicon name: check -->
                            <svg class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                            <span class="text-sm leading-5 text-gray-500">Am I being considerate towards my community?</span>
                        </li>

                        <li class="flex space-x-3">
                            <!-- Heroicon name: check -->
                            <svg class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                            <span class="text-sm leading-5 text-gray-500">Did I check previous posts?</span>
                        </li>

                        <li class="flex space-x-3">
                            <!-- Heroicon name: check -->
                            <svg class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                            <span class="text-sm leading-5 text-gray-500">We are all here to learn. Please Don't bully newbies!</span>
                        </li>

                        <li class="flex space-x-3">
                            <!-- Heroicon name: check -->
                            <svg class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                            <span class="text-sm leading-5 text-gray-500">Am I spamming?</span>
                        </li>

                        <li class="flex space-x-3">
                            <!-- Heroicon name: check -->
                            <svg class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                            <span class="text-sm leading-5 text-gray-500">Serious violators will be banned</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    import {jigo} from '@/axios-auth'
    import JitapEditor from "../../../components/JitapEditor";
    export default {
        name: "NewPost",
        props: ['communityName', 'communityOwner', 'forumProp'],
        data(){
            return {
                title: null,
            }
        },
        validations: {
            title: {
                required
            }
        },
        components: {JitapEditor},
        mounted() {
            this.$refs['newPostTitle'].focus()
            document.getElementById("newPostTitle").focus();
        },
        computed: {
            communityNameForUrl(){
                return this.communityName.replace(/\s/g, "-")
            },
        },
        methods: {
            titleFocusIn(){
                this.$v.title.$reset()
            },
            titleFocusOut(){
                this.$v.title.$touch()
            },
            cancelPressed(){
                this.$router.push({name: 'forumHome'})
            },
            postPressed(){
                this.$v.$touch()
                if(this.$v.$invalid) {
                    return
                }
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                let html = this.$refs.jitapEditor.getHTML()
                let postRequest = {
                    title: this.title,
                    content: html
                }
                console.log("ForumID:", this.forumProp.JID)
                jigo.post(`/v2/community/integrations/forum/newPost/${this.forumProp.JID}`, postRequest)
                    .then((resp) => {
                        if (resp.status !== 201) {
                            console.log("ERROR: post wasn't created successfully")
                        }
                        loader.hide()
                        this.$router.push({name: 'forumHome'})
                    })
                    .catch((err) => {
                        console.error("ERROR: postPressed FAILED", err)
                        loader.hide()
                    })
            }
        }
    }
</script>

<style scoped>
</style>
